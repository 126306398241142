/*
.flex-container {
    display: flex;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}*/

.pdf-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #f0f0f0;
    justify-content: center;
}

.page-wrapper {
    border: 1px solid #ddd;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-wrapper canvas {
    width: 100%;
    height: 100%;
}

/* Styling for the logout button */
.logout-button {
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    margin: 8px 8px;
    background-color: #f0f0f0; /* Red background color */
    color: white; /* White text color */
    font-size: 20px;
}

    /* On hover, darken the background color */
    .logout-button:hover {
        background-color: #0053ff; /* Darker red background color */
    }

    /* On click, darken the background color further */
    .logout-button:active {
        background-color: #0053ff; /* Even darker red background color */
    }
