.floating-jump-box {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 25px;
}

.page-number {
    border: 1px solid #ccc;
    width: 30px;
    padding: 10px;
    font-size: 25px;
    text-align: center;
}

.jump-button {
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 25px;
    text-align: center;
}
