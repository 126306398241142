p, h3 {
    font-size: 28px;
}

.book-card {
    flex: 1;
    max-width: 400px; /* Set a maximum width for the containers */
    width: 100%; /* Take full available width within the max-width */
    margin: 0 10px; /* Adjust margin as needed to control the spacing */
    padding: 30px;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
}

.paypal-button {
    width: 280px;
    height: 60px;
    /*background-color: #0070ba;*/
    background-color: green;
    color: white;
    font-size: 30px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.paypal-button:hover {
    background-color: darkgreen;
    /*background-color: #00558f;*/
}