.floating-logout-box {
	position: fixed;
	top: 20px;
	left: 20px;
	background-color: #ffffff;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	font-size: 25px;
}

/* Styling for the logout button */
.logout-button {
	border: none;
	padding: 8px 16px;
	cursor: pointer;
	border-radius: 4px;
	font-size: 14px;
	margin: 8px 8px;
	background-color: #c41a09; /* Red background color */
	color: white; /* White text color */
	font-size: 20px;
}

/* On hover, darken the background color */
	.logout-button:hover {
		background-color: #a31607; /* Darker red background color */
	}

/* On click, darken the background color further */
	.logout-button:active {
		background-color: #c41a09; /* Even darker red background color */
	}
