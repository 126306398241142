/* styles.css */
.container-wrapper {
    display: flex;
    justify-content: center; /* Center horizontally */
    /*align-items: center;*/ /* Center vertically */
}

.container {
    flex: 1;
    max-width: 400px; /* Set a maximum width for the containers */
    width: 100%; /* Take full available width within the max-width */
    margin: 0 10px; /* Adjust margin as needed to control the spacing */
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
}

heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 5px;
}

.input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 25px;
}

.forgot-password-link {
    margin-top: 30px;
    font-size: 18px;
    color: #0056b3;
}

.forgot-password-link:hover {
    color: #d32f2f;
}

.forgot-password-link:active {
    color: #0056b3;
}

.button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 25px;
}

.button:hover {
    background-color: #0056b3;
}
